var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('section', [_c('v-row', [_c('v-col', [_c('BaseTableSimple', {
    staticClass: "member-organization-user-table",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.emails,
      "item-key": "id",
      "showTitle": false,
      "items-per-page": 10
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "deleteEmail"
                },
                on: {
                  "click": function click($event) {
                    return _vm.deleteEmail(item.id);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-trash-can ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett e-post")])])];
      }
    }, {
      key: "item.id",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.email",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }], null, true)
  }, [_c('v-spacer')], 1)], 1)], 1), _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "emailInput",
      "placeholder": "E-post",
      "rules": _vm.validateIsEmail,
      "required": ""
    },
    model: {
      value: _vm.memberOrganizationEmail,
      callback: function callback($$v) {
        _vm.memberOrganizationEmail = $$v;
      },
      expression: "memberOrganizationEmail"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "5"
    }
  }, [_c('v-btn', {
    attrs: {
      "data-cy": "addEmail",
      "color": "primary"
    },
    on: {
      "click": _vm.addEmail
    }
  }, [_vm._v(" Legg til ")])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }

import { api } from "@/api/api";
import { ApiGetMemberOrganizationUserDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import BaseTableSimple from "@/components/shared/table/BaseTableSimple.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateIsEmail, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  name: "MemberOrganizationUserModal",
  components: { BaseModalForm, BaseTableSimple },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    organizationId: {
      type: Number,
      required: true,
    },
  },
  emits: ["close"],
  setup(props, { refs }) {
    const store = useStore<StoreState>();
    const emails = ref<ApiGetMemberOrganizationUserDto[]>([]);
    const memberOrganizationEmail = ref("");

    const loadAllEmails = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        emails.value = (await api.organization.getMemberOrganizationUsers(props.organizationId)).data;
      });
    };

    const deleteEmail = async (userId: number) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.organization.deleteMemberOrganizationUser(props.organizationId, userId);
        openNotification(store, NotificationItemType.Success, "E-posten er slettet");
        resetAddEmailForm();
        loadAllEmails();
      });
    };

    const addEmail = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const isValid = (refs.form as Vue & { validate: () => boolean }).validate();
        if (!isValid || !memberOrganizationEmail.value) {
          return;
        }
        await api.organization.postMemberOrganizationUserAsync(props.organizationId, {
          email: memberOrganizationEmail.value,
        });
        openNotification(store, NotificationItemType.Success, "E-posten er lagt til");
        resetAddEmailForm();
        loadAllEmails();
      });
    };

    const resetAddEmailForm = () => {
      (refs.form as Vue & { reset: () => void }).reset();
    };

    onMounted(() => {
      loadAllEmails();
    });

    return {
      headers,
      emails,
      memberOrganizationEmail,
      validateNotEmpty,
      validateIsEmail,
      loadAllEmails,
      addEmail,
      deleteEmail,
    };
  },
});

const headers = [
  { text: "ID", value: "id" },
  { text: "Handlinger", value: "actions" },
  { text: "E-post", value: "email" },
];

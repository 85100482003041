<script>
import BaseTable from "@/components/shared/table/BaseTable.vue";
export default {
  name: "BaseTableSimple",
  components: { BaseTable },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    hidePaginator: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    innerHeaders() {
      const test = this.headers.map((currentHeader) => ({ ...currentHeader }));
      return test;
    },
  },
};
</script>
<template>
  <div class="base-table-simple">
    <BaseTable
      data-cy="baseTable"
      v-bind="$attrs"
      :headers="innerHeaders"
      :items="items"
      :hidePaginator="hidePaginator"
      :scrollable="scrollable"
      :showTitle="showTitle"
      v-on="$listeners"
    >
      <template v-for="(_, slot) of $scopedSlots" #[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </BaseTable>
  </div>
</template>
<style scoped>
.base-table-simple {
  padding-bottom: 15px;
}
</style>

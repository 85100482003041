var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-table-simple"
  }, [_c('BaseTable', _vm._g(_vm._b({
    attrs: {
      "data-cy": "baseTable",
      "headers": _vm.innerHeaders,
      "items": _vm.items,
      "hidePaginator": _vm.hidePaginator,
      "scrollable": _vm.scrollable,
      "showTitle": _vm.showTitle
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(props) {
          return [_vm._t(slot, null, null, props)];
        }
      };
    })], null, true)
  }, 'BaseTable', _vm.$attrs, false), _vm.$listeners))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('section', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "nameInput",
      "label": "Navn",
      "rules": _vm.validateNotEmpty,
      "required": ""
    },
    model: {
      value: _vm.singleMainOffice.name,
      callback: function callback($$v) {
        _vm.$set(_vm.singleMainOffice, "name", $$v);
      },
      expression: "singleMainOffice.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "data-cy": "phoneInput",
      "label": "Telefonnummer",
      "rules": _vm.validateIsNorwegianPhoneNumber,
      "required": ""
    },
    model: {
      value: _vm.singleMainOffice.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.singleMainOffice, "phone", $$v);
      },
      expression: "singleMainOffice.phone"
    }
  }), _c('v-switch', {
    attrs: {
      "data-cy": "isActiveInput",
      "label": "Er aktiv"
    },
    on: {
      "change": _vm.updateMemberOrganizationStatus
    },
    model: {
      value: _vm.singleMainOffice.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.singleMainOffice, "isActive", $$v);
      },
      expression: "singleMainOffice.isActive"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "data-cy": "orgNoInput",
      "label": "Organisasjonsnummer",
      "rules": !_vm.singleMainOffice.inheritOrgNoFromParent ? _vm.validateIsOrganizationNumber : [],
      "disabled": _vm.singleMainOffice.inheritOrgNoFromParent,
      "required": !_vm.singleMainOffice.inheritOrgNoFromParent
    },
    model: {
      value: _vm.singleMainOffice.orgNo,
      callback: function callback($$v) {
        _vm.$set(_vm.singleMainOffice, "orgNo", $$v);
      },
      expression: "singleMainOffice.orgNo"
    }
  }), _vm.isRegionOfficeModal ? _c('v-checkbox', {
    attrs: {
      "label": "Bruk samme org.nr. som hovedkontor"
    },
    model: {
      value: _vm.singleMainOffice.inheritOrgNoFromParent,
      callback: function callback($$v) {
        _vm.$set(_vm.singleMainOffice, "inheritOrgNoFromParent", $$v);
      },
      expression: "singleMainOffice.inheritOrgNoFromParent"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "data-cy": "emailInput",
      "label": "E-post",
      "rules": _vm.validateIsEmail,
      "required": ""
    },
    model: {
      value: _vm.singleMainOffice.email,
      callback: function callback($$v) {
        _vm.$set(_vm.singleMainOffice, "email", $$v);
      },
      expression: "singleMainOffice.email"
    }
  }), !_vm.isRegionOfficeModal ? _c('v-text-field', {
    attrs: {
      "data-cy": "clientAccountNumberInput",
      "label": "Klientkontonummer",
      "rules": _vm.validateClientAccountNumber
    },
    model: {
      value: _vm.singleMainOffice.clientAccountNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.singleMainOffice, "clientAccountNumber", $$v);
      },
      expression: "singleMainOffice.clientAccountNumber"
    }
  }) : _vm._e()], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }